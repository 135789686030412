
import ReviewHelpful from '@/components/review/ReviewHelpful.vue';
import ReviewComments from '@/components/review/ReviewComments.vue';
import Review from './Review.vue';

export default {
  name: 'ReviewItem',
  components: {
    ReviewHelpful,
    ReviewComments,
    Review,
  },
  props: {
    id: { type: Number, required: true },
    user: { type: Object, required: true },
    userChildrenAge: { type: Number, required: true },
    createdAt: { type: String, required: true },
    deletedAt: { type: String, default: '' },
    rating: { type: Number, required: true },
    images: { type: Array, default: () => [] },
    comment: { type: String, default: '' },
    adminComment: { type: String, default: '' },
    teacherComment: { type: String, default: '' },
    adminCommentedAt: { type: String, default: '' },
    teacherCommentedAt: { type: String, default: '' },
    supportCount: { type: Number, default: 0 },
    productTitle: { type: String, default: '' },
    teacher: { type: Object, default: () => ({ id: 0, name: '' }) },
    reportStatus: {
      type: String,
      default: 'NONE',
    },
    isBlind: {
      type: Boolean,
      default: false,
    },
    isTeacherCommentBlind: {
      type: Boolean,
      default: false,
    },
    teacherCommentDeletedAt: {
      type: String,
      default: '',
    },
    index: { type: Number, required: true },
  },
};
